<template>
  <!--    -->
  <div>
    <div class="text-danger font-weight-bold">
      ***หากต้องการเปิดใช้ถอนออโต้ จำเป็นต้องลงทะเบียนทั้ง <b><u>KBiz</u></b> และ <b><u>SMS</u></b>***
    </div>
    <div class="row">
      <div class="col-sm-4 col-lg-3">
        <h5>
          KBiz
          <AutoBankingStatus :status="!!kbizAccount" />
          <br>
          <small class="text-muted">(รองรับฝากและถอน)</small>
        </h5>
      </div>

      <div class="col-sm-8 col-lg-9 pt-2">
        <b-form-checkbox
            v-if="kbizAccount"
            id="kbizAccountStatus"
            v-model="kbizAccount.isEnabled"
            name="kbizAccountStatus"
            switch
        >
          เปิดใช้งาน
        </b-form-checkbox>
        <AutoBankingLinkButton
            :status="!!kbizAccount"
            @click="$bvModal.show('kbiz-account-modal')"
        />
        <KBizAccountModal :agent-bank-account-id="bankAccountForm.id" :kbiz-account="kbizAccount" />
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-sm-4 col-lg-3">
        <h5>
          KPlus <small>(Mobile App)</small>
          <AutoBankingStatus :status="!!kplusAccount" />
          <br>
          <small class="text-muted">(รองรับฝากและถอน)</small>
        </h5>
      </div>

      <div class="col-sm-8 col-lg-9 pt-2">
        <b-form-checkbox
            v-if="kplusAccount"
            id="kplusAccountStatus"
            v-model="kplusAccount.isEnabled"
            name="kplusAccountStatus"
            switch
        >
          เปิดใช้งาน
        </b-form-checkbox>
        <AutoBankingLinkButton
            :status="!!kplusAccount"
            @click="$bvModal.show('kplus-account-modal')"
        />
        <KPlusAccountModal :agent-bank-account-id="bankAccountForm.id" :kplus-account="kplusAccount" />
      </div>
    </div>
    <hr />

    <!--    -->
    <div class="row">
      <div class="col-sm-4 col-lg-3">
        <h5>
          SMS
          <AutoBankingStatus :status="!!bankingSmsReceiver" />
          <br>
          <small class="text-muted">(รองรับฝากเท่านั้น)</small>
        </h5>
      </div>

      <div class="col-sm-8 col-lg-9 pt-2">
        <b-form-checkbox
            v-if="bankingSmsReceiver"
            id="bankingSmsReceiverStatus"
            v-model="bankingSmsReceiver.isEnabled"
            name="bankingSmsReceiverStatus"
            switch
        >
          เปิดใช้งาน
        </b-form-checkbox>
        <AutoBankingLinkButton
            :status="!!bankingSmsReceiver"
            @click="$bvModal.show('sms-receiver-modal')"
        />
        <SmsReceiverModal :agent-bank-account-id="bankAccountForm.id" :banking-sms-receiver="bankingSmsReceiver" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'AutoKBankForm',
  components: {
    KBizAccountModal: () => import('./kbiz-account-modal'),
    KPlusAccountModal: () => import('./kplus-account-modal'),
    SmsReceiverModal: () => import('../auto-banking-sms-modal'),
    AutoBankingStatus: () => import('../auto-banking-status'),
    AutoBankingLinkButton: () => import('../auto-banking-link-button'),
  },
  props: {
    bankAccountForm: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        kbizAccount: null,
        kplusAccount: null,
        bankingSmsReceiver: null,
      })
    }
  },
  data() {
    return {
      kbizAccount: null,
      kplusAccount: null,
      bankingSmsReceiver: null
    }
  },
  watch: {
    'kbizAccount.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('kbiz')
      }
    },
    'kplusAccount.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('kplus')
      }
    },
    'bankingSmsReceiver.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('sms')
      }
    },
  },
  created() {
    this.kbizAccount = this.bankAccountForm.kbizAccount
    this.kplusAccount = this.bankAccountForm.kplusAccount
    this.bankingSmsReceiver = this.bankAccountForm.bankingSmsReceiver
  },
  methods: {
    ...mapActions(['toggleBankingStatus']),
    toggleStatus(banking) {
      this.toggleBankingStatus({
        id: this.bankAccountForm.id,
        banking,
      })
    }
  }
})
</script>

<style scoped>

</style>